import xlsx from 'xlsx'
import Vue from 'vue'
const notify = params => Vue.prototype.$notify(params)

export const pasteJson = (json, cellPosition, rows, headers) => {
  json.rows.map((row, rowIndex) => {
    row.map((cell, cellIndex) => {
      const headerIndex = cellIndex + cellPosition.column
      const header = headers[headerIndex]

      const currentCellIndex = cellPosition.index + rowIndex
      if (rows[currentCellIndex]) {
        rows[currentCellIndex][header] = cell
      } else {
        const row = {}
        row[header] = cell
        rows.push(row)
      }
    })
  })
}

export const readFile = files => {
  return new Promise(resolve => {
    files.forEach(file => {
      const reader = new FileReader()
      reader.onload = e => {
        const data = new Uint8Array(e.target.result)
        const workbook = xlsx.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const csv = xlsx.utils.sheet_to_csv(worksheet)
        const rows = csv.split('\n')
        const splitRows = rows.map(row => row.split(','))
        const headers = ['vin', 'vrm', 'mileage']
        const removeHeaders = splitRows.filter(
          row => !row.some(v => headers.includes(v.toLowerCase()))
        )
        let removeEmpty = removeHeaders.filter(row => !row.every(v => !v))
        if (removeEmpty.length > 75) {
          notify({
            text:
              'Only 75 rows from your file have been uploaded as this is the maximum for a single batch'
          })
          removeEmpty = removeEmpty.slice(0, 75)
        }
        resolve(removeEmpty)
      }
      reader.readAsArrayBuffer(file)
    })
  })
}

export const keyMap = {
  ArrowDown: (row, col) => [row + 1, col],
  ArrowUp: (row, col) => [row - 1, col],
  ArrowLeft: (row, col) => [row, col - 1],
  ArrowRight: (row, col) => [row, col + 1],
  Down: (row, col) => [row + 1, col],
  Up: (row, col) => [row - 1, col],
  Left: (row, col) => [row, col - 1],
  Right: (row, col) => [row, col + 1],
  Enter: (row, col) => [row + 1, col]
}
