<template>
  <div>
    <div class="table-container">
      <table
        class="table is-fullwidth is-striped is-bordered is-size-7-mobile is-scrollable"
        @drop.stop.prevent="onDragDrop"
        @dragover.stop.prevent
        @dragenter.stop.prevent
      >
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index">
              <span class="heading is-size-7 is-inline-block is-marginless">
                {{ header }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="selection">
          <tr v-for="(row, rowIndex) in sheet.rows" :key="rowIndex">
            <td
              class="is-paddingless"
              :style="{ width: headerWidth }"
              v-for="(header, colIndex) in headers"
              :key="colIndex"
            >
              <input
                class="input is-uppercase is-static"
                @focus="handleClick(rowIndex)"
                @input="onText($event, { header, rowIndex, colIndex })"
                @keyup.down="handleKeys($event, header, [rowIndex, colIndex])"
                @keyup.up="handleKeys($event, header, [rowIndex, colIndex])"
                @keyup.left="handleKeys($event, header, [rowIndex, colIndex])"
                @keyup.right="handleKeys($event, header, [rowIndex, colIndex])"
                @keyup.enter="handleKeys($event, header, [rowIndex, colIndex])"
                :value="row[header]"
                :data-test-id="`cell-${rowIndex}-${colIndex}`"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="columns">
      <div class="column is-narrow">
        <div class="content">
          <p class="tag is-warning">75 rows maximum</p>
          <p class="is-size-7 has-text-grey">
            Drag &amp; drop an .xls(x) file onto the table or enter data
            manually
          </p>
        </div>
      </div>
      <div class="column has-text-right">
        <button
          class="button is-rounded is-soft"
          :class="{ 'is-loading': loading }"
          :disabled="loading"
          @click="$emit('submit', sheet)"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { pasteJson, readFile, keyMap } from '../services/batch-data.service'
export default {
  name: 'BatchDataInput',
  props: {
    headers: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    name: '',
    sheet: { rows: [] },
    tableLength: 501,
    clipboardData: []
  }),
  created() {
    this.sheet.rows = new Array(3).fill().map(Object)
  },
  computed: {
    headerWidth() {
      return `${(1 / this.headers.length) * 100}%`
    }
  },
  methods: {
    handleClick(index) {
      // push a new row when last row is in focus
      const total = this.sheet.rows.length
      const max = 75
      if (total >= max && index + 1 === total)
        this.$notify({
          text:
            'You cannot add another row as you have reached the maximum for a single batch'
        })
      else if (index + 1 === total && total < max) this.sheet.rows.push({})
    },
    handleKeys($event, header, index) {
      // +1 values because selectors are not zero-based index)
      const [row, col] = keyMap[$event.key](index[0], index[1]).map(v => v + 1)
      // if out of bounds, do nothing
      const oob = [
        row < 1,
        col < 1,
        row > this.sheet.rows.length,
        col > this.headers.length
      ].some(v => v)
      if (oob) return
      // focus on new position
      const path = `tbody > tr:nth-child(${row}) > td:nth-child(${col}) > input`
      return document.querySelector(path).focus()
    },
    async onDragDrop(e) {
      const { headers, sheet } = this
      const rows = await readFile(e.dataTransfer.files, sheet.rows)
      pasteJson(
        { rows },
        { header: headers[0], index: 0, column: 0 },
        sheet.rows,
        headers
      )
      this.$forceUpdate()
    },
    onText($event, { header, rowIndex }) {
      const target = event.target || event.srcElement || event.originalTarget
      this.sheet.rows[rowIndex][header] = target.value.replace(/\n/g, '')
    }
  }
}
</script>

<style lang="sass" scoped>
.input
  padding: 0px 6px
  margin: 6px 0px
</style>
